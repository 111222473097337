import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProfileSetting } from '@/firebase/utils'
import { successMsg } from '@/utils/toast'
import QRCode from 'qrcode.react'
import dynamic from 'next/dynamic'
import NextImage from '@/utils/Image'
import CopyField from '@/components/copyField'
import walletSelector from '@/redux/selectors/wallet'
import { FormattedMessage } from 'react-intl'

// const CopyField = dynamic(() => import('@/components/copyField'))

export default function DepositModalContent() {
  const { internalWallet } = useSelector(walletSelector)

  const [profileData, setProfileData] = useState({})

  const getProfile = async () => {
    const res = await getProfileSetting()
    if (res) {
      setProfileData(res)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])
  return (
    <div className="space-y-8 max-w-sm">
      <div className="space-y-4">
        <div className="p-1 bg-white/5 w-fit rounded-full mx-auto relative">
          <NextImage
            width={42}
            height={42}
            src="/svgs/BSV.svg"
            className="p-1.5 bg-white/5 rounded-full border-[3px] border-white/10"
          />
        </div>
        <div>
          <h3 className="font-inter font-medium text-lg">
            <FormattedMessage
              id="wallet.settings.depositModal.title"
              defaultMessage="Deposit Bitcoin SV"
            />
          </h3>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="p-2.5 bg-white rounded-md">
          <QRCode
            value={
              internalWallet.paymail
                ? // internalWallet.address
                  // ? 'bitcoin:' + internalWallet.address + '?sv'
                  internalWallet.paymail
                : ''
            }
            size={150}
          />
        </div>
      </div>
      <div className="w-[22rem] space-y-8 flex flex-col">
        <div className="flex items-start flex-col space-y-2 w-full">
          <label className="font-inter text-sm font-medium">
            <FormattedMessage
              id="wallet.settings.depositModal.paymailLabel"
              defaultMessage=""
            />
          </label>
          <CopyField
            value={internalWallet?.paymail || ''}
            className="w-full"
            onCopy={() => successMsg(`Paymail Successfully Copied`)}
          />
        </div>
        <div className="flex items-start flex-col space-y-2 w-full !mb-8 md:!mb-0">
          <label className="font-inter text-sm font-medium">
            <FormattedMessage
              id="wallet.settings.depositModal.bitcoinSVAddressLabel  "
              defaultMessage="BitcoinSV Address"
            />
          </label>
          <CopyField
            value={internalWallet?.address || ''}
            className="w-full"
            onCopy={() => successMsg(`BitcoinSV Address Successfully Copied`)}
          />
        </div>
      </div>
    </div>
  )
}
