import React, { useEffect, useState } from 'react'
import NextImage from '@/utils/Image'
import { useSelector } from 'react-redux'
import { errorMsg, successMsg } from '@/utils/toast'
import walletSelector from '@/redux/selectors/wallet'

const TwetchPayComponent = ({ openTwetchModel, setOpenTwetchModel }) => {
  const [amount, setAmount] = useState(0)

  const [selectionActive, setSelectionActive] = React.useState(false)
  const { internalWallet } = useSelector(walletSelector)
  const isTwetchInstalled = window.bitcoin && window.bitcoin.isTwetch
  const modelClose = () => {
    setOpenTwetchModel(!openTwetchModel)
  }
  useEffect(() => {
    if (isTwetchInstalled) {
      try {
        window.bitcoin.connect()
      } catch (err) {
        console.log(err)
      }
    } else {
      window.open('https://twetch.com/downloads', '_blank')
      modelClose()
    }
  }, [])
  const handleTwetchPay = async () => {
    try {
      const response = await window.twetch.abi({
        contract: 'payment',
        outputs: [
          {
            to: internalWallet.paymail,
            sats: Number(amount),
          },
        ],
      })
      if (response.txid) {
        successMsg('Successfully Transfered Money')
        modelClose()
      }
    } catch (error) {
      errorMsg('Transaction Failed')
      modelClose()
    }
  }

  return (
    <>
      <div className="space-y-8 max-w-sm w-[440px]">
        <div className="space-y-4">
          <div className="p-1 bg-white/5 w-fit rounded-full mx-auto relative">
            <NextImage
              fill
              src="/svgs/BSV.svg"
              className="p-1.5 bg-white/5 rounded-full border-[3px] border-white/10"
            />
          </div>
          <div>
            <h3 className="font-inter font-medium text-lg">
              TwetchPay Transfer
            </h3>
          </div>
        </div>
        <div className="flex gap-x-4 ">
          <h4>Enter amount to transfer:</h4>
          <div
            className={`flex flex-row items-start mouse-pointer rounded-lg py-[6px] px-[6px] font-normal font-inter text-base border ${
              selectionActive ? 'border-[#008000]' : 'border-white/5'
            } focus:ring-[#008000] focus:ring-2 focus:outline-none`}
            onClick={() => setSelectionActive(!selectionActive)}
          >
            <div className="my-auto w-full flex justify-center items-center">
              <p className="text-[#F7941D] mr-1">SATS</p>
              <input
                value={amount}
                placeholder="Enter amount"
                onChange={(e) => setAmount(e.target.value)}
                onBlur={() => {
                  if (isNaN(amount)) {
                    setAmount(0)
                  }
                }}
                className="bg-transparent w-full"
              />
            </div>
          </div>
        </div>
        <div className="cursor-pointer flex items-center justify-center gap-x-4">
          <div
            className="cursor-pointer flex flex-row items-center hover:bg-white/[0.07] rounded-lg py-3 justify-center bg-white/[0.05] w-full"
            onClick={handleTwetchPay}
          >
            <h3 className="font-inter font-medium text-md">Transfer</h3>
          </div>

          <div
            className="cursor-pointer flex flex-row items-center hover:bg-white/[0.07] rounded-lg py-3 justify-center  bg-white/[0.05] w-full"
            onClick={modelClose}
          >
            <h3 className="font-inter font-medium text-md">Decline</h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default TwetchPayComponent
