import { handCashConnect } from '@/config/handcashConfig'

export default class HandCashService {
  constructor(authToken) {
    this.account = handCashConnect.getAccountFromAuthToken(authToken)
  }

  async getProfile() {
    return this.account.profile.getCurrentProfile()
  }

  async getWalletDetails() {
    return this.account.wallet.getSpendableBalance()
  }

  async pay({ destination, amount, currencyCode, notes }) {
    return this.account.wallet.pay({
      payments: [{ destination, amount, currencyCode }],
      description: notes.length > 0 ? notes : 'Transfered from handcash',
    })
  }

  getRedirectionUrl() {
    return handCashConnect.getRedirectionUrl()
  }
}
