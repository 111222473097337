import React, { useState } from 'react'

export default function CopyField({
  value,
  onCopy,
  label,
  className,
  full,
  id,
  ...props
}) {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    setIsCopied(true)
    navigator?.clipboard?.writeText(value)
    onCopy && onCopy()
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <div
      className={`flex flex-row justify-between items-center ${className}`}
      {...props}
    >
      {label && (
        <label htmlFor={id} className="text-sm font-medium mb-1.5">
          {label}
        </label>
      )}
      <div className="bg-white/5 border border-white/20 rounded-lg shadow-sm flex flex-row  grow-[2]">
        <input
          value={value}
          readOnly
          className="text-base font-normal my-auto flex-1 px-3.5 py-2.5 bg-transparent"
        />
        <button
          disabled={isCopied}
          className="flex flex-row items-center gap-2 bg-white/5 px-4 border-l border-white/20"
          onClick={handleCopy}
        >
          {isCopied ? (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="20px"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1022_18010)">
                <path
                  d="M4.16602 12.5C3.38945 12.5 3.00116 12.5 2.69488 12.3731C2.2865 12.204 1.96204 11.8795 1.79288 11.4711C1.66602 11.1649 1.66602 10.7766 1.66602 10V4.33334C1.66602 3.39992 1.66602 2.93321 1.84767 2.57669C2.00746 2.26308 2.26243 2.00812 2.57603 1.84833C2.93255 1.66667 3.39926 1.66667 4.33268 1.66667H9.99935C10.7759 1.66667 11.1642 1.66667 11.4705 1.79354C11.8789 1.9627 12.2033 2.28715 12.3725 2.69553C12.4993 3.00182 12.4993 3.3901 12.4993 4.16667M10.166 18.3333H15.666C16.5994 18.3333 17.0661 18.3333 17.4227 18.1517C17.7363 17.9919 17.9912 17.7369 18.151 17.4233C18.3327 17.0668 18.3327 16.6001 18.3327 15.6667V10.1667C18.3327 9.23325 18.3327 8.76654 18.151 8.41002C17.9912 8.09642 17.7363 7.84145 17.4227 7.68166C17.0661 7.50001 16.5994 7.50001 15.666 7.50001H10.166C9.23259 7.50001 8.76588 7.50001 8.40937 7.68166C8.09576 7.84145 7.84079 8.09642 7.68101 8.41002C7.49935 8.76654 7.49935 9.23325 7.49935 10.1667V15.6667C7.49935 16.6001 7.49935 17.0668 7.68101 17.4233C7.84079 17.7369 8.09576 17.9919 8.40937 18.1517C8.76588 18.3333 9.2326 18.3333 10.166 18.3333Z"
                  stroke="white"
                  strokeOpacity="0.5"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1022_18010">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}

          {full && (
            <span className="font-medium text-base text-white/50">Copy</span>
          )}
        </button>
      </div>
    </div>
  )
}
