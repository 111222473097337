import useHandcashConnect from '@/hooks/useHandcashConnect'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { errorMsg, successMsg } from '@/utils/toast'
import {
  handleConnectModal,
  handleHandCashModal,
  getInternalWalletBalance,
  getHistoriesThunk,
} from '@/redux/slices/wallet'
import HandCashService from '@/utils/HandCashService'
import usePresistClick from '@/hooks/usePresistClick'
import authSelector from '@/redux/selectors/auth'
import NextImage from '@/utils/Image'

import HeaderButton from '../button/HeaderButton'
import DepositModalContent from '../DepositModalContent'
import TwetchPayComponent from '../twetchPay'
import Spinner from '../spinner'
import walletSelector from '@/redux/selectors/wallet'
import betSlipsSelector from '@/redux/selectors/betSlips'
import { FormattedMessage } from 'react-intl'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function ConnectWallet({ home }) {
  const dispatch = useDispatch()
  const { connectModal, handCashModal, internalWallet } =
    useSelector(walletSelector)
  const { totalBetPrice } = useSelector(betSlipsSelector)

  const { user, isAuthenticated } = useSelector(authSelector)
  const [selectionActive, setSelectionActive] = React.useState(false)
  const [value, setValue] = useState(totalBetPrice)
  const [notesValue, setNotesValue] = useState('')
  const [transfering, setTransfering] = useState(false)

  const [walletModel, setWalletModel] = useState(false)
  const [handModel, setHandModel] = useState(false)
  const [relysiaModal, setRelysiaModal] = useState(false)
  const [openTwetchModel, setOpenTwetchModel] = useState(false)

  const { redirectionUrl, isUserLoggedIn, handcashProfile, handcashWallet } =
    useHandcashConnect()
  const router = useRouter()

  const openModal = () => {
    setWalletModel(true)
  }

  const openHandCashModal = () => {
    setHandModel(true)
  }

  const closeModal = () => {
    setWalletModel(false)
    dispatch(handleConnectModal(false))
  }

  const closeHandCashModal = () => {
    setHandModel(false)
    dispatch(handleHandCashModal(false))
  }

  const handleHandleCash = () => {
    if (isUserLoggedIn) {
      if (handcashProfile && handcashProfile.publicProfile) {
        setHandModel(true)
        return
      }
      if (typeof window !== 'undefined') {
        window.open(redirectionUrl, '_self')
      }
    } else {
      errorMsg('Please login first')
      router.push('/login')
    }
  }

  const handleRelysia = () => {
    if (isUserLoggedIn) {
      setRelysiaModal(true)
    } else {
      errorMsg('Please login first')
      router.push('/login')
    }
  }

  useEffect(() => {
    if (connectModal === true) {
      openModal()
    }
  }, [connectModal])

  useEffect(() => {
    if (handCashModal === true) {
      openHandCashModal()
    } else {
    }
  }, [handCashModal])

  const handcashAcceptHandler = async () => {
    if (transfering === true) {
      return
    }
    if (!value || value.toString().length === 0) {
      errorMsg('Please enter value first')
      return
    }
    if (user.handcashAuthToken && internalWallet.paymail) {
      try {
        setTransfering(true)
        const service = new HandCashService(user.handcashAuthToken)

        await service.pay({
          destination: internalWallet.paymail,
          amount: value,
          currencyCode: 'USD',
          notes: notesValue,
        })
        closeHandCashModal()
        successMsg('Transfered Successfully')
        setTransfering(false)
        batch(() => {
          dispatch(getInternalWalletBalance())
          dispatch(getHistoriesThunk())
        })
      } catch (err) {
        setTransfering(false)
        errorMsg(err.message)
      }
    }
  }

  const incrementBetPrice = () => {
    setValue((Number(value) + 0.01)?.toFixed(2))
  }

  const decrementBetPrice = () => {
    if (value > 0) {
      setValue((Number(value) - 0.01)?.toFixed(2))
    }
  }

  const increment = usePresistClick({
    onPresist: () => {
      incrementBetPrice()
    },
    onClick: () => {
      incrementBetPrice()
    },
  })

  const decrement = usePresistClick({
    onPresist: () => {
      decrementBetPrice()
    },
    onClick: () => {
      decrementBetPrice()
    },
  })

  const handleTwetchPay = async () => {
    if (isAuthenticated) {
      setOpenTwetchModel(true)
      setTimeout(() => {
        closeModal()
      }, 1000)
    } else {
      errorMsg('Please login first')
      router.push('/login')
    }
  }
  return (
    <>
      {home ? (
        <HeaderButton
          icon={'/svgs/currency-dollar.svg'}
          active
          className="hover:bg-[#fff]/20 duration-300"
          centered
          label={'signUp.connectWallet'}
          defaultLabel={'Connect Wallet'}
          onClick={openModal}
          imgWidth={16}
          imgHeight={16}
        />
      ) : (
        <HeaderButton
          icon={'/svgs/currency-dollar.svg'}
          active
          className="hover:bg-[#fff]/20 duration-300"
          centered
          label={'Connect'}
          onClick={openModal}
          imgWidth={14}
          imgHeight={14}
        />
      )}

      <Model
        onClose={closeModal}
        isOpen={walletModel}
        showCloseIcon
        modelBG="bg-black/90"
        className="px-6 overflow-auto max-h-screen"
      >
        <div className="space-y-8 max-w-sm w-[440px]">
          <div className="space-y-4">
            <div className="p-1 bg-white/5 w-fit rounded-full mx-auto relative">
              <NextImage
                width={45}
                height={45}
                src="/svgs/BSV.svg"
                className="p-1.5 bg-white/5 rounded-full border-[3px] border-white/10"
              />
            </div>
            <div>
              <h3 className="font-inter font-medium text-lg">
                <FormattedMessage
                  id="walletModal.title"
                  defaultMessage="Connect Wallet"
                />
              </h3>
            </div>
          </div>
          <div className="cursor-pointer flex items-center justify-center flex-col">
            <div
              className="cursor-pointer flex flex-row items-center hover:bg-white/[0.07] rounded-lg py-3 px-5 bg-white/[0.05] w-full relative"
              onClick={handleTwetchPay}
            >
              <NextImage
                width={25}
                height={25}
                src="/svgs/TwetchIcon.svg"
                alt="twetch"
              />
              <h3 className="font-inter font-medium text-md pl-8">Twetch</h3>
            </div>
            <div
              className="flex flex-row items-center rounded-lg py-3 px-5 hover:bg-white/[0.07] bg-white/[0.05] mt-1.5 w-full relative"
              onClick={handleHandleCash}
            >
              <NextImage
                width={25}
                height={25}
                src="/img/handcash-logo.svg"
                alt="handcash"
              />
              <h3 className="font-inter font-medium text-md pl-8">Handcash</h3>
            </div>
            <div
              className="cursor-pointer flex flex-row items-center hover:bg-white/[0.07] rounded-lg py-3 px-5 mt-1.5 bg-white/[0.05] w-full relative"
              onClick={handleRelysia}
            >
              <NextImage
                width={25}
                height={25}
                src="/img/relysiaIcon.svg"
                alt="relessia"
              />
              <h3 className="font-inter font-medium text-md pl-8">Relysia</h3>
            </div>
          </div>
        </div>
      </Model>

      {/* Success Model for HandCash */}

      <Model
        onClose={closeHandCashModal}
        isOpen={handModel}
        showCloseIcon
        modelBG="bg-black/90"
        className="px-6 overflow-auto max-h-screen"
      >
        <div className="space-y-8 max-w-sm w-[440px]">
          <div className="space-y-4">
            <div className="p-1 bg-white/5 w-fit rounded-full mx-auto relative">
              <NextImage
                fill
                src="/svgs/BSV.svg"
                className="p-1.5 bg-white/5 rounded-full border-[3px] border-white/10"
              />
            </div>
            <div>
              <h3 className="font-inter font-medium text-lg">
                HandCash Transfer
              </h3>
            </div>
          </div>
          <div className="flex gap-x-2 items-center">
            <h4>Enter amount: </h4>
            <div
              className={`flex flex-row items-start mouse-pointer rounded-lg py-[6px] px-[6px] font-normal font-inter text-base border ${
                selectionActive ? 'border-[#008000]' : 'border-white/5'
              } focus:ring-[#008000] focus:ring-2 focus:outline-none`}
              onClick={() => setSelectionActive(!selectionActive)}
            >
              <div className="my-auto w-full flex justify-center items-center">
                <p className="text-[#F7941D] mr-1">USD</p>
                <input
                  value={value}
                  placeholder="Enter amount"
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={() => {
                    if (isNaN(value)) {
                      setValue('')
                    }
                  }}
                  className="bg-transparent w-full"
                />
              </div>
              <div className="flex flex-col items-start min-w-fit">
                <div
                  className="rounded-full hover:bg-white/5 relative"
                  {...increment}
                >
                  <NextImage
                    width={16}
                    height={16}
                    src="/svgs/arrow-up-white.svg"
                    alt="arrow-up"
                    className="w-4 h-4"
                  />
                </div>
                <div
                  className="rounded-full hover:bg-white/5 relative"
                  {...decrement}
                >
                  <NextImage
                    width={16}
                    height={16}
                    src="/svgs/arrow-down-white.svg"
                    alt="arrow-down"
                    className="w-4 h-4"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <h4>Enter Notes: </h4>
            <textarea
              className="mouse-pointer rounded-lg py-[6px] px-[6px] font-normal font-inter text-base bg-transparent w-[72%] border-none"
              placeholder="Enter notes"
              onChange={(e) => setNotesValue(e.target.value)}
            />
          </div>
          <div className="cursor-pointer flex items-center justify-center gap-x-4">
            <div
              className="cursor-pointer flex flex-row items-center hover:bg-white/[0.07] rounded-lg py-3 justify-center bg-white/[0.05] w-full"
              onClick={handcashAcceptHandler}
            >
              {transfering ? (
                <Spinner isMarginTop={false} />
              ) : (
                <h3 className="font-inter font-medium text-md">Transfer</h3>
              )}
            </div>

            <div className="cursor-pointer flex flex-row items-center hover:bg-white/[0.07] rounded-lg py-3 justify-center  bg-white/[0.05] w-full">
              <h3
                className="font-inter font-medium text-md"
                onClick={closeHandCashModal}
              >
                Decline
              </h3>
            </div>
          </div>
        </div>
      </Model>

      <Model
        onClose={() => setRelysiaModal(false)}
        isOpen={relysiaModal}
        showCloseIcon
        modelBG="bg-black/90"
        className="px-6 overflow-auto max-h-screen"
      >
        <DepositModalContent />
      </Model>
      <Model
        onClose={() => setOpenTwetchModel(false)}
        isOpen={openTwetchModel}
        showCloseIcon
        modelBG="bg-black/90"
        className="px-6 overflow-auto max-h-screen"
      >
        <TwetchPayComponent
          openTwetchModel={openTwetchModel}
          setOpenTwetchModel={setOpenTwetchModel}
        />
      </Model>
    </>
  )
}
