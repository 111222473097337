import authSelector from '@/redux/selectors/auth'
import walletSelector from '@/redux/selectors/wallet'
import { addUpdateUser } from '@/redux/slices/auth'
import { updateWalletDetails, handleHandCashModal } from '@/redux/slices/wallet'
import HandCashService from '@/utils/HandCashService'
import { removeQueryParam } from '@/utils/removeParams'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function useHandcashConnect() {
  const router = useRouter()
  const dispatch = useDispatch()
  const { user, isAuthenticated } = useSelector(authSelector)
  const { handcash, connectModal, handCashModal } = useSelector(walletSelector)
  const redirectionUrl = new HandCashService().getRedirectionUrl()

  const handlePay = (betAmount) => {
    try {
    } catch (err) {}
  }

  const walletDetailsHandler = (authToken) => {
    dispatch(
      updateWalletDetails({
        authToken,
      }),
    )
  }

  const manualWalletDetailsHandler = (authToken) => {
    walletDetailsHandler(authToken)
  }

  const handleAuthToken = async () => {
    const { authToken } = router.query
    if (authToken) {
      dispatch(
        addUpdateUser({
          uid: user?.uid || '',
          data: {
            handcashAuthToken: authToken,
          },
          onSuccess: () => {
            walletDetailsHandler(authToken)
            dispatch(handleHandCashModal(true))
            removeQueryParam(router, 'authToken')
          },
        }),
      )
    } else {
      if (user?.handcashAuthToken) {
        walletDetailsHandler(user.handcashAuthToken)
      }
    }
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      handleAuthToken()
    }
  }, [user])

  return {
    redirectionUrl,
    isUserLoggedIn: isAuthenticated,
    user: user,
    handcashProfile: handcash.profile,
    handcashWallet: handcash.wallet,
    connectModal: connectModal,
    handCashModal: handCashModal,
    manualWalletDetailsHandler,
  }
}
